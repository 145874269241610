import type { ReportHandler } from 'web-vitals';

const reportWebVitals = (_onPerfEntry?: ReportHandler): void => {
    /*
    if (onPerfEntry != null && onPerfEntry instanceof Function) {
    import('web-vitals')
    .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS(onPerfEntry);
    getFID(onPerfEntry);
    getFCP(onPerfEntry);
    getLCP(onPerfEntry);
    getTTFB(onPerfEntry);
    })
    .catch((error) => {
    console.log(error);
    });
    }
    */
};

export default reportWebVitals;
