import React from 'react';
import { SAPDocumentHeader, formatSapValue, sapFieldNameToLabel } from './SAPTypes';
import Stack from 'react-bootstrap/esm/Stack';
import { ShipmentTrackerChip } from '../../Common/ShipmentTrackerChip';

interface SAPDocumentHeaderProps {
    header?: SAPDocumentHeader;
}

export function SAPDocHeader(props: SAPDocumentHeaderProps) {
    function NameValueRow({ name, Element }: { name: keyof SAPDocumentHeader; Element?: React.FC<any> }) {
        return (
            <Stack direction="horizontal" gap={3}>
                <div style={{ minWidth: '140px' }}>
                    <b>{sapFieldNameToLabel(name)}</b>
                </div>
                <div>{Element != null ? <Element {...props} /> : formatSapValue(name, props.header?.[name])}</div>
            </Stack>
        );
    }

    const Shipment = (props: any) => <ShipmentTrackerChip trackingId={props.header?.U_PHO_TrackInfo} />;

    return (
        <React.Fragment>
            <Stack direction="horizontal" gap={3} style={{ marginBottom: '10px' }}>
                <Stack direction="vertical" gap={1}>
                    <NameValueRow name="CardCode" />
                    <NameValueRow name="CardName" />
                    <NameValueRow name="NumAtCard" />
                </Stack>
                <Stack direction="vertical" gap={1}>
                    <NameValueRow name="DocNum" />
                    <NameValueRow name="U_PHO_DeliveryStat" />
                    <NameValueRow name="U_PHO_TRNO" Element={Shipment} />
                </Stack>
            </Stack>
        </React.Fragment>
    );
}
