import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Styles/index.css';
import './Styles/bootstrap-override.scss';
/* import 'bootstrap/dist/css/bootstrap.min.css'; */
import 'bootstrap-icons/font/bootstrap-icons.css';
import { ThemeProvider } from 'react-bootstrap';
import { ModalProvider } from 'react-modal-hook';
import { ToastContainer } from 'react-toastify';
// import { TopLoadingBar } from './DataLoader';

const clientID = '603655351602-58i3h2sbcn4f54mirg9to9oo7d77ma25.apps.googleusercontent.com';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <ThemeProvider breakpoints={['xxxxl', 'xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']} minBreakpoint="xs">
        {/* <TopLoadingBar /> */}
        <GoogleOAuthProvider clientId={clientID}>
            <ModalProvider>
                <App />
            </ModalProvider>
        </GoogleOAuthProvider>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            closeOnClick={false}
            closeButton={true}
            draggable
            pauseOnHover
            theme="colored"
        />
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
