import React from 'react';
import { ColumnDefinition, ReactTabulator, ReactTabulatorOptions } from 'react-tabulator';
import { useMasterDataFlatPurchaseItemsOnly, useMasterDataFlatSalesItemsOnly, useMasterDataFlatinventoryItemsOnly } from '../../../Hooks/useSapMasterData';
import { nanoid } from 'nanoid';
import { useSapCostCenters } from '../../../Hooks/useSapCostCenters';
import { useSapProjects } from '../../../Hooks/useSapProjectList';
import { useSapSuppliers } from '../../../Hooks/useSapSuppliers';
import TabulatorTables from 'react-tabulator\\lib\\types\\TabulatorTypes.d';
import { Button } from 'react-bootstrap';
import { withFormController } from '../../HOC/withFormController';

interface MasterDataTableProps {
    src: string;
    isInvalid?: boolean;
    disabled: boolean;
    onChange: (value: any) => void;
    items: any[];
    error?: any;
    eligableItems: 'purchase' | 'inventory' | 'sales';
    fields: string[];
    children?: React.ReactNode;
}

function useSapDataAsTabulatorListValues(props: any) {
    const [costCentersDim1] = React.useState<any[]>([]);
    const [costCentersDim2] = React.useState<any[]>([]);
    const [costCentersDim3] = React.useState<any[]>([]);
    const [projects] = React.useState<any[]>([]);
    const [suppliers] = React.useState<any[]>([]);
    const [masterData] = React.useState<any[]>([]);

    const [{ data: costCentersOriginal }] = useSapCostCenters(props.src);
    React.useEffect(() => {
        if (costCentersOriginal != null) {
            costCentersOriginal?.forEach((item: any) => {
                item.value = item.OcrCode;
                item.label = item.OcrCode;
            });
            costCentersDim1.length = 0;
            costCentersDim2.length = 0;
            costCentersDim3.length = 0;
            costCentersDim1.push(...costCentersOriginal.filter((x: any) => x.DimCode === 1));
            costCentersDim2.push(...costCentersOriginal.filter((x: any) => x.DimCode === 2));
            costCentersDim3.push(...costCentersOriginal.filter((x: any) => x.DimCode === 3));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [costCentersOriginal?.length]);

    const [{ data: projectsOriginal }] = useSapProjects(props.src);
    React.useEffect(() => {
        if (projectsOriginal != null) {
            projects.length = 0;
            projects.push(...projectsOriginal);
            projects?.forEach((item) => {
                item.value = item.PrjCode;
                item.label = item.PrjCode;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectsOriginal?.length]);

    const [{ data: suppliersOriginal }] = useSapSuppliers(props.src);
    React.useEffect(() => {
        if (suppliersOriginal != null) {
            suppliers.length = 0;
            suppliers.push(...suppliersOriginal);
            suppliers?.forEach((item) => {
                item.value = item.CardCode;
                item.label = item.CardCode;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suppliersOriginal?.length]);

    let hook;
    switch (props.eligableItems) {
        case 'inventory':
            hook = useMasterDataFlatinventoryItemsOnly;
            break;
        case 'purchase':
            hook = useMasterDataFlatPurchaseItemsOnly;
            break;
        case 'sales':
            hook = useMasterDataFlatSalesItemsOnly;
            break;
        default:
            hook = useMasterDataFlatPurchaseItemsOnly;
            break;
    }
    const [{ data: masterDataOriginal }] = hook(props.src);
    React.useEffect(() => {
        if (masterDataOriginal != null) {
            masterData.length = 0;
            masterData.push(...masterDataOriginal);
            masterData?.forEach((item) => {
                item.value = item.ItemCode;
                item.label = item.ItemCode;
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterDataOriginal?.length]);

    return [{ suppliers, projects, masterData, costCentersDim1, costCentersDim2, costCentersDim3 }];
}

export function SapB1MasterDataTable(props: MasterDataTableProps): JSX.Element {
    const refTable = React.useRef<TabulatorTables.Tabulator>();
    const [items, setItems] = React.useState([...props.items, { id: nanoid() }]);
    const [{ suppliers, projects, masterData, costCentersDim1, costCentersDim2, costCentersDim3 }] = useSapDataAsTabulatorListValues(props);

    function formatterIfEmpty(cell: any, formatterParams: any): any {
        const value = cell.getValue();
        const data = cell.getRow().getData();
        const { classes } = formatterParams;
        if (data.ItemCode != null && (value === '' || value === undefined || value === null)) {
            cell.getElement().classList.add(classes);
        } else {
            cell.getElement().classList.remove(classes);
        }
        return value;
    }

    const columnsTemplates: (ColumnDefinition | any)[] = [
        {
            title: 'Photo',
            field: 'ItemPhoto',
            width: 70,
            formatter: 'image',
            hozAlign: 'center',
            formatterParams: {
                urlPrefix: `${process.env.PUBLIC_URL}/SAPImages/`,
                urlSuffix: '.jpg',
                height: '22px',
                width: 'auto'
            },
            mutator: function (_value: any, data: any) {
                return data.ItemCode;
            },
            tooltip: function (e: any, cell: any, _onRendered: any): any {
                const value = cell.getValue();
                const el = document.createElement('div');
                el.innerHTML = `<div style="width:300px; height:300px"><img style="max-width: 100%;max-height: 100%;"src="/SAPImages/${
                    value as string
                }.jpg"></img><div>`;
                return el;
            }
        },
        {
            title: 'Item Code',
            field: 'ItemCode',
            width: 130,
            vertAlign: 'middle',
            hozAlign: 'left',
            editor: 'list',
            editorParams: {
                values: masterData,
                clearable: true,
                autocomplete: true,
                emptyValue: null,
                allowEmpty: false,
                listOnEmpty: true,
                itemFormatter: (label: any, value: any, item: any, element: any) => {
                    return '<strong>' + value + '</strong> - ' + item.ItemName;
                },
                filterFunc: (term: any, label: any, value: any, item: any) => {
                    term = term.toLowerCase();
                    return item?.value?.toLowerCase().includes(term) || item?.ItemName?.toLowerCase().includes(term);
                }
            }
        },
        {
            title: 'Item Name',
            field: 'ItemDescription',
            // width: 320,
            vertAlign: 'middle',
            hozAlign: 'left',
            editor: 'input',
            editorParams: {
                selectContents: true
            },
            editable: (cell: any) => {
                var data = cell.getRow().getData();
                const masterDataItem = masterData.find((x) => x.ItemCode === data.ItemCode);
                return masterDataItem?.InvntItem === 'N';
            }
        },
        {
            title: 'Free Text',
            field: 'FreeText',
            width: 150,
            hozAlign: 'left',
            vertAlign: 'middle',
            editor: 'input'
        },
        {
            title: 'Qty',
            field: 'Quantity',
            width: 80,
            vertAlign: 'middle',
            hozAlign: 'right',
            editor: 'number',
            mutateLink: '_PriceTotal',
            editorParams: {
                min: 0,
                selectContents: true
            },
            formatter: formatterIfEmpty,
            formatterParams: {
                classes: 'bg-danger'
            }
        },
        {
            title: 'Price w/o VAT',
            field: 'Price',
            width: 100,
            vertAlign: 'middle',
            hozAlign: 'right',
            editor: 'number',
            mutateLink: '_PriceTotal',
            editorParams: {
                min: 0,
                selectContents: true
            },
            formatter: 'money',
            formatterParams: {
                symbol: '€ ',
                thousand: ' ',
                negativeSign: true
            }
        },
        {
            title: 'Price Total',
            field: '_PriceTotal',
            width: 100,
            vertAlign: 'middle',
            hozAlign: 'right',
            mutator: (value: any, data: any, type: any, params: any, component: any) => {
                const quantity = data.Quantity ?? 0;
                const price = data.Price ?? 0;
                return quantity * price;
            },
            bottomCalc: 'sum',
            bottomCalcFormatter: 'money',
            bottomCalcFormatterParams: {
                symbol: '€ ',
                negativeSign: true
            },
            formatter: 'money',
            formatterParams: {
                symbol: '€ ',
                thousand: ' ',
                negativeSign: true
            }
        },
        {
            title: 'Supplier',
            field: 'LineVendor',
            width: 200,
            vertAlign: 'middle',
            hozAlign: 'left',
            editor: 'list',
            editorParams: {
                values: suppliers,
                autocomplete: true,
                clearable: true,
                listOnEmpty: true,
                allowEmpty: true,
                itemFormatter: (_label: any, value: string, item: any, _element: any) => {
                    return '<strong>' + value + '</strong> - ' + item.CardName;
                },
                filterFunc: (term: any, label: any, value: any, item: any) => {
                    term = term.toLowerCase();
                    return item?.value?.toLowerCase().includes(term) || item?.CardName?.toLowerCase().includes(term);
                }
            }
        },
        {
            title: 'Purpose',
            field: 'U_SFT_UCEL',
            editor: 'input',
            vertAlign: 'middle',
            width: 150,
            formatter: formatterIfEmpty,
            formatterParams: {
                classes: 'bg-warning'
            }
        },
        {
            title: 'Link Text',
            field: 'U_PHO_LinkLines',
            editor: 'input',
            vertAlign: 'middle',
            width: 150
        },
        {
            title: 'BU',
            field: 'CostingCode',
            width: 80,
            vertAlign: 'middle',
            hozAlign: 'center',
            editor: 'list',
            editorParams: {
                values: costCentersDim1,
                clearable: true,
                listOnEmpty: true,
                allowEmpty: false,
                itemFormatter: (_label: any, value: string, item: any, _element: any) => {
                    return '<strong>' + value + '</strong> - ' + item.OcrName;
                },
                filterFunc: (term: any, label: any, value: any, item: any) => {
                    term = term.toLowerCase();
                    return item?.value?.toLowerCase().includes(term) || item?.OcrName?.toLowerCase().includes(term);
                }
            },
            formatter: formatterIfEmpty,
            formatterParams: {
                classes: 'bg-danger'
            }
        },
        {
            title: 'CC',
            field: 'CostingCode2',
            width: 80,
            vertAlign: 'middle',
            hozAlign: 'center',
            editor: 'list',
            editorParams: {
                values: costCentersDim2,
                clearable: true,
                autocomplete: true,
                listOnEmpty: true,
                allowEmpty: false,
                itemFormatter: (_label: any, value: string, item: any, _element: any) => {
                    return '<strong>' + value + '</strong> - ' + item.OcrName;
                },
                filterFunc: (term: any, label: any, value: any, item: any) => {
                    term = term.toLowerCase();
                    return item?.value?.toLowerCase().includes(term) || item?.OcrName?.toLowerCase().includes(term);
                }
            },
            formatter: formatterIfEmpty,
            formatterParams: {
                classes: 'bg-danger'
            }
        },
        {
            title: 'Budget',
            field: 'CostingCode3',
            width: 80,
            vertAlign: 'middle',
            hozAlign: 'center',
            editor: 'list',
            editorParams: {
                values: costCentersDim3,
                clearable: true,
                autocomplete: true,
                listOnEmpty: true,
                allowEmpty: true,
                itemFormatter: (_label: any, value: string, item: any, _element: any) => {
                    return '<strong>' + value + '</strong> - ' + item.OcrName;
                },
                filterFunc: (term: any, label: any, value: any, item: any) => {
                    term = term.toLowerCase();
                    return item?.value?.toLowerCase().includes(term) || item?.OcrName?.toLowerCase().includes(term);
                }
            },
            formatter: formatterIfEmpty,
            formatterParams: {
                classes: 'bg-warning'
            }
        },
        {
            title: 'Project',
            field: 'ProjectCode',
            width: 100,
            vertAlign: 'middle',
            hozAlign: 'center',
            editor: 'list',
            editorParams: {
                values: projects,
                clearable: true,
                autocomplete: true,
                listOnEmpty: true,
                allowEmpty: true,
                itemFormatter: (_label: any, value: string, item: any, _element: any) => {
                    return '<strong>' + value + '</strong> - ' + item.PrjName;
                },
                filterFunc: (term: any, label: any, value: any, item: any) => {
                    term = term.toLowerCase();
                    return item?.value?.toLowerCase().includes(term) || item?.PrjName?.toLowerCase().includes(term);
                }
            },
            formatter: formatterIfEmpty,
            formatterParams: {
                classes: 'bg-warning'
            }
        }
    ];

    const options: ReactTabulatorOptions = {
        height: '30vh',
        rowHeight: 32,
        index: 'id',
        movableRows: true,
        clipboard: true,
        clipboardPasteParser: function (clipboard) {
            //turn clipboard data into array
            console.log(clipboard);
            return ['ahoj']; //return array
        },
        clipboardPasteAction: 'insert',
        addRowPos: 'bottom',
        columns: [
            {
                field: '~',
                title: '',
                formatter: 'handle',
                width: 30,
                minWidth: 30,
                headerSort: false
            },
            { title: '#', formatter: 'rownum', width: 20, vertAlign: 'middle', hozAlign: 'center' }
        ]
    };

    props.fields.forEach((fieldName) => {
        const fieldTemplate = columnsTemplates.find((x) => x.field === fieldName);
        if (!fieldTemplate) throw new Error(`invalid field name ${fieldName}`);
        options.columns?.push(fieldTemplate);
    });

    options.columns?.push({
        title: 'Actions',
        headerSort: false,
        formatter: 'buttonCross',
        width: 50,
        hozAlign: 'center',
        vertAlign: 'middle',
        cellClick: function (e: any, cell: TabulatorTables.Tabulator.CellComponent) {
            const data = cell.getRow().getData();
            if (data.ItemCode != null) {
                cell.getRow().delete();
            }
        }
    });

    function cellEdited(cell: TabulatorTables.Tabulator.CellComponent): void {
        const fieldName = cell.getField();
        const row = cell.getRow();
        const rowId = row.getIndex();
        const table = cell.getTable();
        const value = cell.getValue();
        const oldValue = cell.getOldValue();
        switch (fieldName) {
            case 'ItemCode':
                const masterDataItem = masterData.find((x) => x.ItemCode === value);

                // update the row and reset some of the settings
                table.updateRow(rowId, { ItemDescription: masterDataItem?.ItemName, Quantity: '', U_PHO_LinkLines: masterDataItem.Link });
                table.redraw(true);

                row.getCell('ItemDescription').getElement().classList.remove('changedSAPItemName');

                // if was artificialy created line, we will add a new one
                if (oldValue == null) {
                    table.addRow({ id: nanoid() });
                }
                break;

            case 'ItemDescription':
                if (value !== oldValue && oldValue !== '' && value !== '') cell.getElement().classList.add('changedSAPItemName');
                break;
        }
    }

    function dataChanged(): void {
        const data = refTable.current?.getData() ?? [];
        const dataWithoutLastLine = data.filter((x: any) => x.ItemCode != null);
        dataWithoutLastLine.forEach((x: any) => {
            delete x._PriceTotal;
        });

        props?.onChange(dataWithoutLastLine);
    }

    React.useEffect(() => {
        refTable.current?.redraw(true);
        if (props.disabled === true) {
            const el = document.createElement('div');
            el.classList.add('tabulator-disabled');
            refTable.current?.element.appendChild(el);
        } else {
            refTable.current?.element.querySelector('.tabulator-disabled')?.remove();
        }
    }, [props.disabled]);

    const errorStyleCondition = props.error != null || props.isInvalid ? { borderColor: 'red' } : {};

    React.useEffect(() => {
        //        if (props.items.length === 0) {
        setItems([...props.items, { id: nanoid() }]);
        //      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.items)]);

    return (
        <React.Fragment>
            <ReactTabulator
                style={errorStyleCondition}
                onRef={(r) => (refTable.current = r.current)}
                options={options}
                data={items}
                events={{ cellEdited, dataChanged }}
            />
            <br />
            <Button
                className="me-2"
                variant="secondary"
                size="sm"
                onClick={() => {
                    refTable.current?.import('csv', '.csv');
                }}
            >
                import from csv (experimental)
            </Button>
            {props.children}
        </React.Fragment>
    );
}

export const SAPMasterDataTableFromControl = withFormController(SapB1MasterDataTable);
