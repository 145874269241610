import React from 'react';
import { useAxiosEx } from '../DataLoader';
import { UserToken } from '../Types/types';

export function useSearchCache(user: UserToken | null) {
    const [{ data: jiraSearchData, loading: loadingJira }] = useAxiosEx(`/api-jira/search?search=*`);
    const [{ data: sapSearchData, loading: loadingSap }] = useAxiosEx(`/api-sap/db/live/master-data-flat`);

    function tranformSapData(data: any[]) {
        data.forEach((item: any) => {
            item.searchResult = 'sap item';
            item.label = `${item.ItemCode} - ${item.ItemName}`;
            item.keywords = [item.ItemCode, item.ItemName].map((x) => x.toLowerCase());
            item.sublabel =
                item.InvntItem === 'Y' ? (
                    <React.Fragment>
                        OnHand: {item.OnHand} OnOrder: {item.OnOrder} IsCommited: {item.IsCommited}{' '}
                        <span className={item.IsAvailable > 0 ? 'fw-bold text-success' : ''}>IsAvailable: {item.IsAvailable}</span>
                        <br />
                    </React.Fragment>
                ) : (
                    'Non Inventory Item'
                );
            item.link = null;
            item.image = `${process.env.PUBLIC_URL}/SAPImages/${item.ItemCode}.jpg`;
        });
    }

    const searchData = React.useMemo(() => {
        tranformSapData(sapSearchData ?? []);
        return [...(jiraSearchData ?? []), ...(sapSearchData ?? [])];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jiraSearchData, sapSearchData, user?.g_access_token]);

    return [{ data: searchData ?? [], loading: loadingJira || loadingSap }];
}
