import ReactTabulatorEx from '../../../Tabulator/TabulatorEx';
import { Tabulator } from 'react-tabulator';
import { SAPDbSource, SAPDocStatus, SAPDocType } from './SAPTypes';
import { tabulatorRowFormatterDocs } from '../../../Tabulator/TabulatorEx-formatters';

/*
    TODO:
    - row formattter

    
*/

interface SAPDocumentListProps {
    sapDbSource: SAPDbSource;
    sapDocType: SAPDocType;
    docStatus?: SAPDocStatus;
}

export function SAPDocumentList({ sapDbSource, sapDocType, docStatus }: SAPDocumentListProps) {
    const options: Tabulator.Options = {
        pagination: true,
        paginationMode: 'remote',
        ajaxURL: `/api-sap/db/${sapDbSource}/sap-documents`,
        ajaxParams: { sapDocType, docStatus },
        // paginationSize: 20,
        paginationCounter: 'rows',
        height: 'calc(100vh - 190px)',
        rowFormatter: tabulatorRowFormatterDocs
    };

    function onClick(row: any) {
        console.log(row);
    }

    return <ReactTabulatorEx options={options} extendedOptions={{}} events={onClick} />;
}

export const SAPSalesOrders = (props: Omit<SAPDocumentListProps, 'sapDocType'>) => <SAPDocumentList sapDocType={SAPDocType.SalesOrder} {...props} />;
export const SAPARInvoices = (props: Omit<SAPDocumentListProps, 'sapDocType'>) => <SAPDocumentList sapDocType={SAPDocType.ARInvoice} {...props} />;
