import React from 'react';
import { useAxiosEx } from '../DataLoader';
import { type UserToken } from '../Types/types';

const url = `/api-jira/frontendinfo`;
/*
export function useFrontendInfo(user: UserToken | null): any {
    const [{ data, _isLoading, _isError }, _doFetch, setRefresh] = useDataApi(url, {});

    React.useEffect(() => {
        setRefresh(true);
    }, [user?.g_access_token]);

    return data;
}
*/
export function useFrontendInfo(user: UserToken | null): any {
    const [{ data, loading, error }, refetch] = useAxiosEx(url);

    React.useEffect(() => {
        void refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.g_access_token]);

    return [data, loading, error, refetch];
}
